import React, { useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { ThemeProvider } from 'styled-components';

import { Box, Button, Grid } from '@core';
import { Animation, ImageCore, LinkWrapper, Text } from '@components';
import { usePlansValuesContext } from '@context';
import { trackCustomGA4Event } from '@shared/google-utils';
import { GA4_EVENTS } from '@shared/constants/google';
import { FadeInTitle, TitleWithIncrementalDiscount } from '@shared/deals-helpers';
import { formatLink } from '@shared/format-helpers';
import { dealsTheme } from '@theme';
import image from '@images/blog-banner/black-friday/bf-price-cut.png';

export const BannerComponent = ({
  t,
  isLoading,
  currency,
  discountPercentage,
  extraTime,
  pricePerMonth,
  subscriptionPeriod,
}) => {
  const bannerRef = useRef(null);
  const [isIntersecting, setIsIntersecting] = useState(false);

  useEffect(() => {
    if (!isIntersecting) {
      const observer = new IntersectionObserver(([entry]) =>
        setIsIntersecting(entry.isIntersecting)
      );
      observer.observe(bannerRef.current);

      return () => {
        observer.disconnect();
      };
    }
  }, [isIntersecting]);

  useEffect(() => {
    if (isIntersecting) {
      trackCustomGA4Event({ eventName: GA4_EVENTS.bannerView });
    }
  }, [isIntersecting]);

  return (
    <ThemeProvider theme={dealsTheme}>
      <div ref={bannerRef}>
        <Grid.Container
          backgroundColor="bannerBackground"
          display="flex"
          justifyContent="space-between"
          flexWrap={{ _: 'wrap', sm: 'nowrap' }}
          overflow="hidden"
          borderRadius="md"
        >
          <Grid.Row alignItems="center">
            <Grid.Col
              size={{ _: 12, sm: 4 }}
              py={{ _: 8, sm: 0 }}
              pl={16}
              pr={{ _: 16, sm: 0 }}
              display="flex"
              justifyContent="center"
            >
              <ImageCore src={image} maxHeight={160} />
            </Grid.Col>
            <Grid.Col
              size={{ _: 12, sm: 8 }}
              pt={{ _: 4, sm: 32 }}
              pb={{ _: 24, sm: 32 }}
              px={16}
              display={{ _: 'flex', sm: 'block' }}
              flexDirection={{ _: 'column', sm: 'unset' }}
              alignItems={{ _: 'center', sm: 'unset' }}
            >
              <Text.Subheading2 textAlign={{ _: 'center', sm: 'unset' }}>
                {discountPercentage ? (
                  <TitleWithIncrementalDiscount
                    finalDiscountPercentage={!isLoading && discountPercentage}
                    // @TODO change to black friday title at some point
                    i18nKey="campaigns:spring.title"
                    components={[
                      <FadeInTitle
                        as="span"
                        color="danger"
                        fontFamily="inherit"
                        whiteSpace="nowrap"
                        key={0}
                        fontSize={20}
                        lineHeight="25px"
                      >
                        <Box display="inline-flex" justifyContent="center" minWidth={50} />
                      </FadeInTitle>,
                    ]}
                  />
                ) : (
                  t('deals:header.getDeal')
                )}
              </Text.Subheading2>
              <Text.Body3Strong mb={16} minHeight={20} textAlign={{ _: 'center', sm: 'unset' }}>
                {!isLoading && (
                  <Animation.FadeIn as="span">
                    <Trans
                      i18nKey="deals:header.description"
                      values={{
                        planDetail: t(`deals:header.planDeal.${subscriptionPeriod}`, {
                          currency,
                          pricePerMonth,
                        }),
                        extraComment: extraTime && t(`plans:monthsExtra.${extraTime}`),
                      }}
                      components={[<Text.Body3Strong as="span" color="danger" key={0} />]}
                    />
                  </Animation.FadeIn>
                )}
              </Text.Body3Strong>
              <LinkWrapper link={formatLink(t('navigation:paths.get'))}>
                <Box width={193}>
                  <Button
                    type="button"
                    fullWidth
                    onClick={() => trackCustomGA4Event({ eventName: GA4_EVENTS.bannerClick })}
                  >
                    {t('deals:common.grabDeal')}
                  </Button>
                </Box>
              </LinkWrapper>
            </Grid.Col>
          </Grid.Row>
        </Grid.Container>
      </div>
    </ThemeProvider>
  );
};

const BlackFridayBlogBanner = () => {
  const { t } = useTranslation();
  const { isLoading, suggestedPlan } = usePlansValuesContext();

  return (
    <BannerComponent
      t={t}
      isLoading={isLoading}
      currency={suggestedPlan?.currency}
      discountPercentage={suggestedPlan?.discountPercentage}
      extraTime={suggestedPlan?.extraTime}
      pricePerMonth={suggestedPlan?.pricePerMonth}
      subscriptionPeriod={suggestedPlan?.subscriptionPeriod}
    />
  );
};

export default BlackFridayBlogBanner;
